import { fetchWrapperPortal } from "./fetch";

export const getById = async (id) =>
  await fetchWrapperPortal({
    url: `events/${id}`,
    method: `GET`,
    addBearer: true,
  });

export const getActive = async (dir = "future") =>
  await fetchWrapperPortal({
    url: `events/?date=${dir}&active=true`,
    method: `GET`,
    addBearer: true,
  });

export const getActiveStreams = async (daysInPast = 30, filterState = 'LIVESCHEDULED') =>
  await fetchWrapperPortal({
    url: `events/streams/?daysInPast=${daysInPast}&filterState=${filterState}&origin=dev`, //TODO change to prod before deploy
    method: `GET`,
    addBearer: true,
  });
  

export const deleteById = async (id) =>
  await fetchWrapperPortal({
    url: `events/${id}`,
    method: `DELETE`,
    addBearer: true,
  });

export const update = async (id, payload) =>
  await fetchWrapperPortal({
    url: `events/${id}`,
    method: `PUT`,
    addBearer: true,
    body: JSON.stringify(payload),
  });
